<template>
  <div class="account">
    <van-nav-bar
      title="账户资金"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-cell-group :border="false">
        <van-cell
          :border="false"
          title="微信收款账户"
          value="查看明细"
          icon="paid"
          center
        />
        <van-cell :border="false" is-link center @click="earning(1)">
          <template #title>
            <span class="money_pref">&yen;</span>
            <span class="money">{{zhanghu.weixinzhanghu ? zhanghu.weixinzhanghu : '0.00'}}</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group :border="false">
        <van-cell
          :border="false"
          title="余额支付收款账户"
          value="查看明细"
          icon="balance-pay"
          center
        />
        <van-cell :border="false" is-link center @click="earning(2)">
          <template #title>
            <span class="money_pref">&yen;</span>
            <span class="money">{{zhanghu.yuezhanghu ? zhanghu.yuezhanghu : '0.00'}}</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group :border="false">
        <van-cell
          :border="false"
          title="店铺保障金"
          value="查看明细"
          icon="sign"
          center
        />
        <van-cell :border="false" is-link center @click="earning(3)">
          <template #title>
            <span class="money_pref">&yen;</span>
            <span class="money">{{zhanghu.baozhangjin ? zhanghu.baozhangjin : '0.00'}}</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group :border="false">
        <van-cell
          :border="false"
          title="银行卡管理"
          value="暂未绑定"
          is-link
          value-class="bank_value"
          center
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "Account",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      zhanghu: {}
    }
  },
  mounted() {
    // this.init();
    console.log("dddd");
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.getearning();
    },
    // 获取账户数据
    getearning() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/earning/get_earning")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.zhanghu = res.data.data;
            console.log(that.zhanghu);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 进入充值页面
    earning(index) {
      this.$router.push({
        name: "Earning",
        query: {
          type: index,
          t: new Date().getTime()
        }
      });
    }
  },
}
</script>
<style lang="less">
.account {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  .content {
    overflow: hidden;
    .van-cell-group {
      margin-bottom: 10px;
      overflow: hidden;
      .van-cell {
        .van-cell__left-icon {
          font-size: 20px;
        }
        .van-cell__title {
          flex: 1;
          text-align: left;
          .money {
            font-size: 20px;
            font-weight: 450;
          }
        }
        .van-cell__value {
          color: #466AC1;
        }
        .bank_value {
          color: #999;
        }
      }
    }
  }
}
</style>